const showMap = () => {
  const isHomePage = document.querySelector('.background-main')
  const homeMap = document.querySelector('#map')
  if (isHomePage) {
    homeMap.classList.toggle('toggle-z-index');
  }
}

const displayMap = () => {
  const displayMapButton = document.getElementById('map-button')
  if (displayMapButton) {
    displayMapButton.addEventListener("click", (event) => {
      console.log('You just clicked on the button');
      showMap();
    });
  }
}

export { displayMap }
